<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Застройщик'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Создать Застройщика'"
      :title="'Создать Застройщика'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">LLC Full Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'llc_full_name'"
              :label="'llc_full_name'"
              :type="'text'"
              :placeholder="'LLC Full Name'"
              :error-messages="validationErrors['llcFullName']"
              isBordered
            />
          </div>

          <p class="form-create__label">LLC Short Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'llc_short_name'"
              :label="'llc_short_name'"
              :type="'text'"
              :placeholder="'LLC Short Name'"
              :error-messages="validationErrors['llcShortName']"
              isBordered
            />
          </div>

          <p class="form-create__label">Legal Address</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'legal_address'"
              :label="'legal_address'"
              :type="'text'"
              :placeholder="'Legal Address'"
              :error-messages="validationErrors['legalAddress']"
              isBordered
            />
          </div>

          <p class="form-create__label">Post Address</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'post_address'"
              :label="'post_address'"
              :type="'text'"
              :placeholder="'Post Address'"
              :error-messages="validationErrors['postAddress']"
              isBordered
            />
          </div>

          <p class="form-create__label">INN</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'inn'"
              :label="'inn'"
              :type="'text'"
              :placeholder="'INN'"
              :error-messages="validationErrors['inn']"
              isBordered
            />
          </div>

          <p class="form-create__label">KPP</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'kpp'"
              :label="'kpp'"
              :type="'text'"
              :placeholder="'KPP'"
              :error-messages="validationErrors['kpp']"
              isBordered
            />
          </div>

          <p class="form-create__label">OGRN</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'ogrn'"
              :label="'ogrn'"
              :type="'text'"
              :placeholder="'OGRN'"
              :error-messages="validationErrors['ogrn']"
              isBordered
            />
          </div>

          <p class="form-create__label">Credits Limit</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'credits_limit'"
              :label="'credits_limit'"
              :type="'number'"
              :placeholder="'Credits Limit'"
              :error-messages="validationErrors['creditsLimit']"
              isBordered
            />
          </div>

          <!-- <div class="input-container">
            <MainCheckbox :label="'Активный'" :id="'is_active'" :set-value="formDataToSend" />
          </div> -->

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
// import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "UserCreate",
  metaInfo: {
    title: "Создать Менеджера",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        llc_full_name: "",
        legal_address: "",
        post_address: "",
        inn: "",
        kpp: "",
        ogrn: "",
        credits_limit: "",

        // is_active: false,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    // MainCheckbox,
  },
  mounted() {},
  computed: {
    ...mapState("users", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.USERS_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",

        llc_full_name: this.formDataToSend.llc_full_name.length
          ? this.formDataToSend.llc_full_name.trim()
          : "",
        llc_short_name: this.formDataToSend.llc_short_name.length
          ? this.formDataToSend.llc_short_name.trim()
          : "",
        legal_address: this.formDataToSend.legal_address.length
          ? this.formDataToSend.legal_address.trim()
          : "",
        post_address: this.formDataToSend.post_address.length
          ? this.formDataToSend.post_address.trim()
          : "",
        inn: this.formDataToSend.inn.length ? this.formDataToSend.inn.trim() : "",
        kpp: this.formDataToSend.kpp.length ? this.formDataToSend.kpp.trim() : "",
        ogrn: this.formDataToSend.ogrn.length ? this.formDataToSend.ogrn.trim() : "",
        credits_limit: +this.formDataToSend.credits_limit,

        // is_active: this.formDataToSend.is_active,
      };
      this.$store.dispatch("users/createDeveloper", data);
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.DEVELOPER_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
  },
};
</script>

<style lang="scss" scoped></style>
